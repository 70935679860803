import { AEMPage, EditableButton, EditableCustomImage } from '@fcamna/aem-library';
import React from 'react';

import withAnimatedNavigation, { AnimatedNavigationProps } from '../../../../utils/withAnimatedNavigation';
import { getAEMHost } from '../../config';
import { Page, PageContent, PageFooter, PageHeader } from '../Page';
import { Text } from '../wrappedLibraryComponents/Text';

const SiteStructure = (props: AnimatedNavigationProps) => {
  return (
    <AEMPage pagePath={`${process.env.AEM_CONTENT_BASE}/aem-structure`} aemHost={getAEMHost()}>
      <Page>
        <PageHeader>
          <Text name="header" />
        </PageHeader>
        <PageContent>
          <Text name="p1" />
          <EditableCustomImage name="directory-structure" style={{ alignSelf: 'center' }} />
          <Text name="p1-part2" />

          <Text name="p2" />
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%', fontSize: '14px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <EditableCustomImage name="amna-ford-author" />
              <EditableCustomImage name="amna-lincoln-author" />
            </div>
            <Text name="caption" />
          </div>
          <EditableCustomImage name="directory-structure-brand" style={{ alignSelf: 'center' }} />
          <Text name="caption-2" style={{ width: '100%' }} />

          <Text name="p3" />
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%', fontSize: '14px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <EditableCustomImage name="amna-english" />
              <EditableCustomImage name="amna-french" />
            </div>
            <Text name="caption-1-p3" />
          </div>
          <EditableCustomImage name="directory-structure-locale" style={{ alignSelf: 'center' }} />
          <Text name="caption-2-p3" style={{ width: '100%' }} />

          <Text name="p4" />
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%', fontSize: '14px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <EditableCustomImage name="fpi-author" />
              <EditableCustomImage name="fpi-prod" />
            </div>
            <EditableCustomImage name="directory-structure-root" style={{ alignSelf: 'center' }} />
          </div>
          <Text name="caption-2-p4" style={{ width: '100%' }} />

          <Text name="p5" />
          <div style={{ display: 'flex', flexDirection: 'column', fontSize: '14px', width: '100%', alignItems: 'center', gap: 50 }}>
            <EditableCustomImage name="tile-in-author" />
            <EditableCustomImage name="directory-structure-tiles" />
          </div>
          <Text name="caption-p5" style={{ width: '60%', margin: '0 auto' }} />
        </PageContent>
        <PageFooter>
          <EditableButton name="back-to-home" onClick={() => props.navigateForward!('/')} />
        </PageFooter>
      </Page>
    </AEMPage>
  );
};

export default withAnimatedNavigation(SiteStructure);
