import { AEMPage, EditableButton, EditableCustomImage } from '@fcamna/aem-library';
import styled from 'styled-components';

import withAnimatedNavigation, { AnimatedNavigationProps } from '../../../../utils/withAnimatedNavigation';
import { getAEMHost } from '../../config';
import { Page, PageContent, PageFooter, PageHeader } from '../Page';
import { Text } from '../wrappedLibraryComponents/Text';

const CenteredSection = styled.div`
  display: flex;
  justify-content: start;
  margin: 0 auto;
`;

const Composition = (props: AnimatedNavigationProps) => {
  return (
    <AEMPage pagePath={`${process.env.AEM_CONTENT_BASE}/assembler-quickstart/composition`} aemHost={getAEMHost()}>
      <Page>
        <PageHeader>
          <Text name="header" />
        </PageHeader>
        <PageContent>
          <Text name="intro" />
          <Text name="p1" />
          <EditableCustomImage name="directory" style={{ margin: '50px auto 0' }} />
          <Text name="p2" style={{ justifySelf: 'center' }} />
          <CenteredSection>
            <Text name="home-marker-metadata" />
          </CenteredSection>
          <Text name="p3" />
          <CenteredSection>
            <Text name="author-marker-metadata" />
          </CenteredSection>
          <Text name="checkbpoint" />
          <EditableCustomImage name="directory-with-pages" style={{ margin: '50px auto 0', maxWidth: 1440 }} />
          <Text name="p4" />
        </PageContent>
        <PageFooter>
          <EditableButton
            name="next"
            onClick={() => {
              props.navigateForward!(`/assembler-quickstart/add-header-footer`);
            }}
          />
        </PageFooter>
      </Page>
    </AEMPage>
  );
};

export default withAnimatedNavigation(Composition);
