import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { AEMPage, EditableFlexContainer } from '@fcamna/aem-library';
import { AEMPageModel } from '@fcamna/aem-library/src/utils/page.types';
import { Chip, Link, List, ListItem } from '@fcamna/react-library';
import { fpds } from '@fcamna/shared-component-foundations';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import withAnimatedNavigation from '../../../../utils/withAnimatedNavigation';
import { getAEMHost } from '../../config';
import Code from '../Code';
import { Page, PageContent, PageHeader } from '../Page';
import { Text } from '../wrappedLibraryComponents/Text';

const Title = styled.h1`
  font-family: 'Ford F-1', sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  align-self: start;
`;

const SubTitle = styled.h3`
  font-family: 'Ford F-1', sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 66%;

  .release-image {
    margin-left: -15px;
    width: 225px;
    gap: 0;

    .code-block {
      padding: 5px 0 5px 15px;
    }
  }

  .Chip {
    background-color: ${fpds.primitive.color.skyview[400].value};
    border: none;
    color: ${fpds.primitive.color.fordBlue[1000].value};
    font-family: 'Ford F-1', sans-serif;
    font-size: 18px;
    font-weight: bold;
    margin-top: 6px;
  }
`;

const LeftContent = styled.div`
  width: 300px;
  margin-top: 112px;
  .release-selected {
    font-weight: bolder;
  }
`;

const Table = styled.table`
  width: 100%;
  min-width: 735px;
  margin-bottom: 30px;
  background-color: #fafafb;
  border: 1px solid #d1d5d9;
  border-radius: 8px;
  border-collapse: separate;
  border-spacing: 0;

  tr:nth-child(even) {
    background: white;
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }
`;

const TableBody = styled.tbody`
  border-radius: 8px;
`;

const TableRow = styled.tr`
  width: 100%;
  height: 70px;
`;

const TableData = styled.td`
  display: table-cell;
  padding: 4px 40px;
  width: 50%;
  box-sizing: border-box;
  vertical-align: middle;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  line-height: 24px;
  color: #00142e;
`;

export function Release() {
  const [model, setModel] = useState<AEMPageModel | null>(null);
  const navigate = useNavigate();
  const params = useParams();
  const isAuthor = AuthoringUtils.isInEditor();
  let version = params.version;
  if (isAuthor) {
    const parts = location.pathname.split('/');
    version = parts[parts.length - 1].split('.')[0];
  }

  const basePath = `${process.env.AEM_CONTENT_BASE}/releases`;

  const {
    selected: selectedRelease,
    releases: [latestRelease, ...previousReleases]
  } = useMemo(() => {
    let selected: { version: string; urlVersion: string } = { version: version as string, urlVersion: version as string };
    const releases = Object.keys(model?.[':children'] || {})
      .map((path) => path.replace(`${basePath}/`, ''))
      .filter((path) => !path.includes('/'))
      .sort((a, b) => a.localeCompare(b) * -1)
      .map((v) => {
        const isSelected = v === version;
        const release = { version: v.replace(/-/g, '.'), urlVersion: v, isSelected };
        if (isSelected) {
          selected = release;
        }
        return release;
      });
    if (!version && releases[0]) {
      selected = releases[0];
      releases[0].isSelected = true;
    }
    return { selected, releases };
  }, [basePath, model, version]);

  const path = model ? `${basePath}/${selectedRelease.urlVersion}` : basePath;

  const onClickRelease = (v: string) => () => navigate(`/releases/${v}`);

  const tableData = [
    { label: 'Version', content: `${selectedRelease?.version}` },
    { label: 'UCL Version', content: <Text key={path} name="ucl-version" /> },
    { label: 'Release Date', content: <Text key={path} name="release-date" /> },
    { label: 'Image', content: <Code className="release-image" commands={[`assembler:${selectedRelease?.version}`]}></Code> }
  ];

  return (
    <AEMPage pagePath={path} aemHost={getAEMHost()} onPageModelLoad={(model) => setModel(model)} model={model?.[':children']?.[path]}>
      {model && (
        <Page>
          <PageHeader></PageHeader>
          <PageContent style={{ flexDirection: 'row' }}>
            <LeftContent>
              <List type="none">
                <ListItem onClick={onClickRelease(latestRelease.urlVersion)}>
                  <div className={latestRelease.isSelected ? 'release-selected' : ''}>Latest release - {latestRelease.version}</div>
                </ListItem>
                <ListItem>
                  Previous releases
                  <List type="bullet">
                    {previousReleases.map((preRelease) => (
                      <ListItem key={preRelease.version} onClick={onClickRelease(preRelease.urlVersion)}>
                        <div className={preRelease.isSelected ? 'release-selected' : ''}>{preRelease.version}</div>
                      </ListItem>
                    ))}
                  </List>
                </ListItem>
              </List>
            </LeftContent>
            <RightContent>
              <div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
                <Title>Assembler {selectedRelease.version} Release Notes</Title>
                {latestRelease.isSelected && <Chip isDisabled={true}>Latest</Chip>}
              </div>

              <SubTitle>Release Information</SubTitle>
              <Table>
                <TableBody>
                  {tableData.map((rowData) => (
                    <TableRow key={`${rowData.label}-key`}>
                      <TableData>{rowData.label}</TableData>
                      <TableData>{rowData.content}</TableData>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <SubTitle>What&apos;s included?</SubTitle>
              <EditableFlexContainer name="what-included" key={path} />
              {model?.[':children']?.[`${path}/migration`] && (
                <>
                  <SubTitle>Migration instructions</SubTitle>
                  <Link to={`/releases/${selectedRelease.urlVersion}/migration`} size="small" target="_blank" disabled={false}>
                    Click here to view migration guideline
                  </Link>
                </>
              )}
            </RightContent>
          </PageContent>
        </Page>
      )}
    </AEMPage>
  );
}

export default withAnimatedNavigation(Release);
