import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { EditableRichTextEditor, EditableTextInput, TextInputProps } from '@fcamna/aem-library';
import { fpds } from '@fcamna/shared-component-foundations';
import React, { ChangeEvent, useMemo, useState } from 'react';
import styled from 'styled-components';

export const ErrorMessage = styled(EditableRichTextEditor)<{ color?: string }>`
  .message {
    position: relative;
    h6,
    p {
      color: ${({ color }) => color};
      margin-top: 8px;
      line-height: 18px;
    }
  }
`;

ErrorMessage.defaultProps = {
  color: fpds.primitive.color.red[1000].value,
  className: 'message'
};

export const EditableInput = styled(EditableTextInput)<{ hideLabel?: boolean }>`
  div.Label__wrapper:has(label) {
    display: ${(props) => (props.hideLabel ? 'none' : 'block')};
  }

  label {
    font-size: 24px;
  }
`;

export function EditableRegexInput(
  props: TextInputProps & { regex?: RegExp; onValidChange?: (v: boolean) => void; hideLabel?: boolean; width?: number; isError?: boolean }
) {
  const [isValid, setIsValid] = useState(true);
  const isInEditor = useMemo(() => AuthoringUtils.isInEditor(), []);

  return (
    <div style={{ width: props.width || 500, ...props.style }}>
      <EditableInput
        {...props}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (props.regex) {
            const currentValid = props.regex.test(e.target.value);
            if (props.onValidChange) {
              props.onValidChange(currentValid);
            }
            setIsValid(currentValid);
          }
          if (props.onChange) props.onChange(e);
        }}
        name={props.name}
        removeDefaultStyles={true}
      />
      {(isInEditor || !isValid || props.isError) && <ErrorMessage name={props.name} />}
    </div>
  );
}
