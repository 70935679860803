import { AEMPage, EditableFlexContainer } from '@fcamna/aem-library';
import { ThemeName } from '@fcamna/shared-component-foundations';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import withAnimatedNavigation from '../../../../utils/withAnimatedNavigation';
import { getAEMHost } from '../../config';
import { Page, PageContent, PageHeader } from '../Page';

const Title = styled.h1`
  font-family: 'Ford F-1', sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  align-self: start;
`;

function Migration() {
  const params = useParams();
  const path = `${process.env.AEM_CONTENT_BASE}/releases/${params.version}/migration`;
  return (
    <AEMPage pagePath={path} aemHost={getAEMHost()} themeName={ThemeName.FORD_PRO}>
      <Page>
        <PageHeader>
          <Title> Migration from previous release to {params.version?.replace(/-/g, '.')}</Title>
        </PageHeader>
        <PageContent>
          <EditableFlexContainer />
        </PageContent>
      </Page>
    </AEMPage>
  );
}

export default withAnimatedNavigation(Migration);
