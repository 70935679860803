import './Wizard.scss';

import { Mode, ThemeName, ThemeProvider } from '@fcamna/shared-component-foundations';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import SiteStructure from './components/aem-structure/SiteStructure';
import AddHeaderFooter from './components/assembler-quickstart/AddHeaderFooter';
import Composition from './components/assembler-quickstart/Composition';
import Congratulations from './components/assembler-quickstart/Congratulations';
import Intro from './components/assembler-quickstart/Intro';
import MaintenanceAndErrorPage from './components/assembler-quickstart/MaintenanceAndErrorPage';
import Routing from './components/assembler-quickstart/Routing';
import ChangeDisplayedTile from './components/embed-dynamic-pages/ChangeDisplayedTile';
import CreateTilePage from './components/embed-dynamic-pages/CreateTilePage';
import EmbedCongratulations from './components/embed-dynamic-pages/EmbedCongratulations';
import EmbedIntro from './components/embed-dynamic-pages/EmbedIntro';
import MarkerComponent from './components/embed-dynamic-pages/MarkerComponent';
import Prerequisite from './components/host-assembler/AssemblerHosting';
import JFrogInstructions from './components/JFrogInstructions';
import CreatePage from './components/new-page/CreatePage';
import ProductName from './components/new-page/ProductName';
import Summary from './components/new-page/Summary';
import { PublishInstructions } from './components/PublishInstructions';
import Migration from './components/release/Migration';
import Release from './components/release/Release';
import AppName from './components/tiles/AppName';
import CreateTileInstructions from './components/tiles/CreateTileInstructions';
import TransactionalPage from './components/tiles/TransactionalPage';
import Home from './Home';

const Wizard = () => {
  console.log('CONTENT BASE: ', process.env.AEM_CONTENT_BASE);

  return (
    <ThemeProvider themeName={ThemeName.FORD_PRO} mode={Mode.LIGHT_MODE}>
      <div className="Wizard">
        <Router>
          <Routes>
            <Route path="dynamic-page">
              <Route index element={<TransactionalPage />} />
              <Route path="app-name" element={<AppName />} />
              <Route path="instructions" element={<CreateTileInstructions />} />
            </Route>
            <Route path="new-page">
              <Route index element={<ProductName />} />
              <Route path="create" element={<CreatePage />} />
              <Route path="summary" element={<Summary />} />
            </Route>
            <Route path="assembler-quickstart">
              <Route index element={<Intro />} />
              <Route path="composition" element={<Composition />} />
              <Route path="add-header-footer" element={<AddHeaderFooter />} />
              <Route path="routing" element={<Routing />} />
              <Route path="maintenance-and-error-page" element={<MaintenanceAndErrorPage />} />
              <Route path="congratulations" element={<Congratulations />} />
            </Route>
            <Route path="embed-dynamic-pages">
              <Route index element={<EmbedIntro />} />
              <Route path="create-page-tile" element={<CreateTilePage />} />
              <Route path="marker-component" element={<MarkerComponent />} />
              <Route path="change-displayed-tile" element={<ChangeDisplayedTile />} />
              <Route path="embed-dynamic-page-congratulations" element={<EmbedCongratulations />} />
            </Route>
            <Route path="host-assembler">
              <Route index element={<Prerequisite />} />
            </Route>
            <Route path="aem-structure">
              <Route index element={<SiteStructure />} />
            </Route>
            <Route path="releases/:version?" element={<Release />} />
            <Route path="releases/:version/migration" element={<Migration />} />
            <Route path={`${process.env.AEM_CONTENT_BASE}.html`} element={<Home />} />
            <Route path={`${process.env.AEM_CONTENT_BASE}`}>
              <Route path="new-page.html" element={<ProductName />} />
              <Route path="new-page/create-a-page.html" element={<CreatePage />} />
              <Route path="new-page/summary.html" element={<Summary />} />
              <Route path="dynamic-page.html" element={<TransactionalPage />} />
              <Route path="dynamic-page/app-name.html" element={<AppName />} />
              <Route path="dynamic-page/instructions.html" element={<CreateTileInstructions />} />
              <Route path="assembler-quickstart.html" element={<Intro />} />
              <Route path="assembler-quickstart/composition.html" element={<Composition />} />
              <Route path="assembler-quickstart/add-header-footer.html" element={<AddHeaderFooter />} />
              <Route path="assembler-quickstart/routing.html" element={<Routing />} />
              <Route path="assembler-quickstart/maintenance-and-error-page.html" element={<MaintenanceAndErrorPage />} />
              <Route path="assembler-quickstart/congratulations.html" element={<Congratulations />} />
              <Route path="embed-dynamic-pages.html" element={<EmbedIntro />} />
              <Route path="embed-dynamic-pages/create-page-tile.html" element={<CreateTilePage />} />
              <Route path="embed-dynamic-pages/marker-component.html" element={<MarkerComponent />} />
              <Route path="embed-dynamic-pages/change-displayed-tile.html" element={<ChangeDisplayedTile />} />
              <Route path="embed-dynamic-pages/embed-dynamic-page-congratulations.html" element={<EmbedCongratulations />} />
              <Route path="host-assembler.html" element={<Prerequisite />} />
              <Route path="aem-structure.html" element={<SiteStructure />} />
              <Route path="components/jfrog-instructions.html" element={<JFrogInstructions />} />
              <Route path="components/publish-instructionS.html" element={<PublishInstructions />} />
              <Route path="releases/*" element={<Release />} />
              <Route path="releases/:version/migration.html" element={<Migration />} />
            </Route>
            <Route path="*" element={<Home />} />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default Wizard;
