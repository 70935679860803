import { AEMPage, EditableButton, EditableCustomImage } from '@fcamna/aem-library';
import { useSearchParams } from 'react-router-dom';

import withAnimatedNavigation, { AnimatedNavigationProps } from '../../../../utils/withAnimatedNavigation';
import { getAEMHost } from '../../config';
import { Page, PageCenteredContent, PageFooter, PageHeader } from '../Page';
import { Text } from '../wrappedLibraryComponents/Text';

const EmbedCongratulations = (props: AnimatedNavigationProps) => {
  const [searchParams] = useSearchParams();
  const productName = searchParams.get('productName');
  const pageName = searchParams.get('pageName');
  const pagePath = searchParams.get('pagePath');

  return (
    <AEMPage pagePath={`${process.env.AEM_CONTENT_BASE}/embed-dynamic-pages/embed-dynamic-page-congratulations`} aemHost={getAEMHost()}>
      <Page>
        <PageHeader>
          <Text name="intro" />
        </PageHeader>
        <PageCenteredContent>
          <div style={{ display: 'flex', gap: 30, justifyContent: 'center', marginTop: 15 }}>
            <EditableCustomImage />
            <EditableCustomImage />
            <EditableCustomImage />
          </div>
          <Text
            name="p1"
            replacements={{
              'http://www.assembler-page.com': `${process.env.ASSEMBLER_URL}/${productName}/ford/en-us/root/${pageName}`
            }}
          />
          <Text
            name="p2"
            replacements={{
              'http://www.aem-link.com': `${process.env.AEM_AUTHOR_URL}/editor.html${pagePath}.html`
            }}
          />
        </PageCenteredContent>
        <PageFooter>
          <EditableButton
            name="back-to-home"
            onClick={() => {
              props.navigateForward!('/');
            }}
          />
        </PageFooter>
      </Page>
    </AEMPage>
  );
};

export default withAnimatedNavigation(EmbedCongratulations);
