import { AEMPage, EditableButton } from '@fcamna/aem-library';

import withAnimatedNavigation, { AnimatedNavigationProps } from '../../../../utils/withAnimatedNavigation';
import { getAEMHost } from '../../config';
import { Page, PageContent, PageFooter, PageHeader } from '../Page';
import { Text } from '../wrappedLibraryComponents/Text';

const Intro = (props: AnimatedNavigationProps) => {
  return (
    <AEMPage pagePath={`${process.env.AEM_CONTENT_BASE}/assembler-quickstart`} aemHost={getAEMHost()}>
      <Page>
        <PageHeader>
          <Text name="header" />
        </PageHeader>
        <PageContent>
          <Text
            name="content"
            replacements={{
              'http://new-page-link.com': `/new-page`
            }}
          />
        </PageContent>
        <PageFooter>
          <EditableButton
            name="get-started"
            onClick={() => {
              props.navigateForward!(`/assembler-quickstart/composition`);
            }}
          />
        </PageFooter>
      </Page>
    </AEMPage>
  );
};

export default withAnimatedNavigation(Intro);
