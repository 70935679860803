import { AEMPage, EditableButton, EditableRichTextEditor } from '@fcamna/aem-library';
import { Link } from '@fcamna/react-library';
import { useLocation, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import withAnimatedNavigation, { AnimatedNavigationProps } from '../../../../utils/withAnimatedNavigation';
import { getAEMHost } from '../../config';
import Code from '../Code';
import JFrogInstructions from '../JFrogInstructions';
import { Page, PageContent, PageFooter } from '../Page';
import { Text } from '../wrappedLibraryComponents/Text';

const Subheader = styled(EditableRichTextEditor)`
  h3 {
    font-weight: lighter;
  }
`;

const LatestPackageNote = styled(EditableRichTextEditor)`
  margin-top: 15px;
  h5 {
    font-weight: lighter;
  }
`;

const AEMPageLink = styled(EditableRichTextEditor)`
  &.page-link {
    h3 {
      color: #066fef;

      &:hover {
        text-decoration: none;
      }
    }
  }
`;

const CreateTileInstructions = (props: AnimatedNavigationProps) => {
  const [searchParams] = useSearchParams();
  const searchAppName = searchParams.get('appName');
  const searchProductName = searchParams.get('productName');

  let { productName = 'quantum-logo', appName = 'test', pagePath = '' } = useLocation().state || {};

  productName = productName || searchProductName;
  appName = appName || searchAppName;
  pagePath = pagePath || '';

  const createReactTileCommand = `npx @quantum/create-react-tile@latest --productName="${productName}" --appName="${appName}"`;

  return (
    <AEMPage pagePath={`${process.env.AEM_CONTENT_BASE}/dynamic-page/instructions`} aemHost={getAEMHost()}>
      <Page>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '50px 0 50px',
            alignItems: 'center',
            gap: '15px',
            width: '100%'
          }}>
          <EditableRichTextEditor name="header" />
          <Subheader name="subheader" />

          <div
            style={{
              width: '75%',
              display: 'flex',
              gap: '40px',
              flexDirection: 'column',
              justifyContent: 'flex-start'
            }}>
            <JFrogInstructions />
            <EditableRichTextEditor name="createReactTile" />
            <div>
              <Code commands={[createReactTileCommand]} maxWidth={1228} />
              <LatestPackageNote name="checkIfLatestVersionExists" />
            </div>

            <EditableRichTextEditor name="changeDirectory" />
            <Code commands={[`cd ./${appName}`, `npm start`]} maxWidth={1228} />

            <span style={{ display: 'flex' }}>
              <EditableRichTextEditor name="directToCreatedAEMPage" />
              <Link
                to={`${process.env.AEM_AUTHOR_URL}/editor.html${pagePath}.html`}
                size="small"
                target="_blank"
                disabled={false}
                variant="onImage">
                <AEMPageLink className="page-link" name="AEMPageLink" />
              </Link>
            </span>
            <PageContent>
              <Text name="final" />
            </PageContent>
            <PageFooter>
              <EditableButton onClick={() => props.navigateForward!('/')} name="home" />
            </PageFooter>
          </div>
        </div>
      </Page>
    </AEMPage>
  );
};
export default withAnimatedNavigation(CreateTileInstructions);
