import { AEMPage, EditableButton, EditableCustomImage } from '@fcamna/aem-library';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import withAnimatedNavigation, { AnimatedNavigationProps } from '../../../../utils/withAnimatedNavigation';
import { getAEMHost } from '../../config';
import { Page, PageFooter, PageHeader } from '../Page';
import { Text } from '../wrappedLibraryComponents/Text';

const CenteredSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: start;

  p {
    line-height: 42px;
  }

  .content {
    width: 375px;
    background-color: #00142e;
    box-shadow: 2px 2px 7px 2px rgba(0, 0, 0, 0.7);
    border-radius: 15px;
    padding: 15px 30px;

    p,
    h2 {
      color: white;
    }
  }
`;

const Summary = (props: AnimatedNavigationProps) => {
  const [searchParams] = useSearchParams();
  const productName = searchParams.get('productName');
  const pagePath = searchParams.get('pagePath');
  const pageName = searchParams.get('pageName');

  return (
    <AEMPage pagePath={`${process.env.AEM_CONTENT_BASE}/new-page/summary`} aemHost={getAEMHost()}>
      <Page>
        <PageHeader>
          <Text name="assembler-success-header" />
          <div style={{ display: 'flex', gap: 30, justifyContent: 'center', marginTop: 15 }}>
            <EditableCustomImage />
            <EditableCustomImage />
            <EditableCustomImage />
          </div>
          <CenteredSection>
            <Text
              name="summary"
              replacements={{
                'http://aem-link.com': `${process.env.AEM_AUTHOR_URL}/editor.html${pagePath}.html`,
                'http://assembler-link.com': `${process.env.ASSEMBLER_URL}/${productName}/ford/en-us/root/${pageName}`
              }}
            />
          </CenteredSection>
        </PageHeader>
        <PageFooter>
          <EditableButton style={{ width: 150 }} onClick={() => props.navigateForward!('/')} name="home" />
        </PageFooter>
      </Page>
    </AEMPage>
  );
};

export default withAnimatedNavigation(Summary);
