interface PagePathProps {
  brand?: string;
  languageLocale?: string;
  rootDirectory?: string;
  productName: string;
  pageName?: string;
}

export const toAEMPagePath = ({
  productName,
  brand = 'ford',
  languageLocale = 'en-us',
  rootDirectory = 'root',
  pageName
}: PagePathProps) => {
  pageName = pageName ? `/${pageName}` : '';
  return `/content/quantum-sandbox/${productName}/${brand}/${languageLocale}/${rootDirectory}${pageName}`;
};

export const copyAEMPage = async (
  sourcePage: string,
  targetPage: string,
  pageProperties?: { [index: string]: string }
): Promise<string> => {
  return fetch(process.env.AEM_PAGE_CREATION_URL, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${btoa(process.env.AEM_USER + ':' + process.env.AEM_PASSWORD)}`
    },
    body: JSON.stringify({
      sourcePage,
      targetPage,
      pageProperties: pageProperties || {}
    })
  })
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then(({ author_page_path }) => author_page_path)
    .catch(() => {
      return targetPage;
    });
};
