import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { AEMPage, EditableButton, EditableCustomImage } from '@fcamna/aem-library';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import withAnimatedNavigation, { AnimatedNavigationProps } from '../../../../utils/withAnimatedNavigation';
import { getAEMHost } from '../../config';
import { Page, PageContent, PageFooter, PageHeader } from '../Page';
import PageGenerator from '../PageGenerator';
import { Text } from '../wrappedLibraryComponents/Text';

const StyledSection = styled.div`
  display: grid;
  gap: 0 20px;
  width: 100%;
  grid-template-columns: 695px 695px;
  grid-template-rows: auto;
  margin-top: 50px;

  .cell {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;

    h2 {
      margin-top: 0;
      align-self: flex-start;
    }
  }
`;

const WarningText = styled(Text)`
  p {
    color: #d90606;
  }
`;
function CreatePage(props: AnimatedNavigationProps) {
  const isInEditor = useMemo(() => AuthoringUtils.isInEditor(), []);
  const { productName = 'quantum-logo' } = useLocation().state || {};
  const [pagePath, setPagePath] = useState<string>();
  const [pageName, setPageName] = useState<string>();

  return (
    <AEMPage pagePath={`${process.env.AEM_CONTENT_BASE}/new-page/create-a-page`} aemHost={getAEMHost()}>
      <Page>
        <PageHeader>
          <Text name="title" />
        </PageHeader>
        <PageContent>
          <Text name="content" />
          <PageGenerator
            productName={productName}
            name="page-generator"
            sourcePage={process.env.SAMPLE_ASSEMBLER_STATIC_PAGE}
            onCreated={(pagePath, pageName) => {
              setPagePath(pagePath || '');
              setPageName(pageName);
            }}
          />

          {(pageName || isInEditor) && (
            <>
              <Text
                name="aem-page-link"
                replacements={{
                  'http://page-link.com': `${process.env.AEM_AUTHOR_URL}/editor.html${pagePath}.html`
                }}
              />
              <WarningText name="warning" />
              <StyledSection>
                <Text
                  name="page-instructions"
                  className="cell"
                  replacements={{
                    'https://aem-link.com': `${process.env.AEM_AUTHOR_URL}/editor.html${pagePath}.html`,
                    'http://assembler-link.com': `${process.env.ASSEMBLER_URL}/${productName}/ford/en-us/root/${pageName}`
                  }}
                />
                <div className="cell" style={{ alignItems: 'flex-end' }}>
                  <EditableCustomImage name="publish-instructions" style={{ marginTop: 70 }} />
                </div>
              </StyledSection>
              <Text
                name="see-it-live"
                replacements={{
                  'http://www.new-assembler-page.com': `${process.env.ASSEMBLER_URL}/${productName}/ford/en-us/root/${pageName}`
                }}
              />
            </>
          )}
        </PageContent>
        <PageFooter>
          <EditableButton
            onClick={() => props.navigateForward!(`/new-page/summary?pagePath=${pagePath}&pageName=${pageName}&productName=${productName}`)}
            name="next"
          />
        </PageFooter>
      </Page>
    </AEMPage>
  );
}

export default withAnimatedNavigation(CreatePage);
