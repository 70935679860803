import { AEMPage, EditableButton, EditableCustomImage } from '@fcamna/aem-library';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import withAnimatedNavigation, { AnimatedNavigationProps } from '../../../../utils/withAnimatedNavigation';
import { getAEMHost } from '../../config';
import { PageContent, PageFooter, PageHeader } from '../Page';
import { Text } from '../wrappedLibraryComponents/Text';

const StyledTileDiv = styled(Text)`
  margin: auto;
  width: 100%;
`;

const ChangeDisplayedTile = (props: AnimatedNavigationProps) => {
  const [searchParams] = useSearchParams();
  const productName = searchParams.get('productName');
  const pageName = searchParams.get('pageName');
  const pagePath = searchParams.get('pagePath');

  return (
    <AEMPage pagePath={`${process.env.AEM_CONTENT_BASE}/embed-dynamic-pages/change-displayed-tile`} aemHost={getAEMHost()}>
      <PageHeader>
        <StyledTileDiv name="change-tile-header" />
      </PageHeader>
      <PageContent>
        <StyledTileDiv name="p1" />
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', gap: 50 }}>
          <EditableCustomImage name="celebrate-instructions" style={{ objectFit: 'contain' }} />
          <EditableCustomImage name="celebrate-instructions-2" />
        </div>
        <StyledTileDiv name="p2" />
        <EditableCustomImage name="celebrate-tile" style={{ margin: 'auto' }} />
        <StyledTileDiv
          name="p3"
          replacements={{
            'http://www.assembler-page.com': `${process.env.ASSEMBLER_URL}/${productName}/ford/en-us/root/${pageName}`
          }}
        />
        <EditableCustomImage name="celebrate-tile-hosted" style={{ margin: 'auto' }} />
        <StyledTileDiv name="note" style={{ width: '1200px' }} />
      </PageContent>
      <PageFooter>
        <EditableButton
          name="continue"
          onClick={() =>
            props.navigateForward!(
              `/embed-dynamic-pages/embed-dynamic-page-congratulations?pagePath=${pagePath}&pageName=${pageName}&productName=${productName}`
            )
          }></EditableButton>
      </PageFooter>
    </AEMPage>
  );
};

export default withAnimatedNavigation(ChangeDisplayedTile);
