import { AEMPage, EditableButton, EditableCustomImage } from '@fcamna/aem-library';
import { useSearchParams } from 'react-router-dom';

import withAnimatedNavigation, { AnimatedNavigationProps } from '../../../../utils/withAnimatedNavigation';
import { getAEMHost } from '../../config';
import { Page, PageCenteredContent, PageContent, PageFooter, PageHeader } from '../Page';
import { Text } from '../wrappedLibraryComponents/Text';

const MarkerComponent = (props: AnimatedNavigationProps) => {
  const [searchParams] = useSearchParams();
  const productName = searchParams.get('productName');
  const pageName = searchParams.get('pageName');
  const pagePath = searchParams.get('pagePath');

  return (
    <AEMPage pagePath={`${process.env.AEM_CONTENT_BASE}/embed-dynamic-pages/marker-component`} aemHost={getAEMHost()}>
      <Page>
        <PageHeader>
          <Text name="marker-header" />
        </PageHeader>
        <PageContent>
          <Text name="p1" />
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%', gap: 50 }}>
            <EditableCustomImage name="marker-image" style={{ objectFit: 'contain' }} />
            <EditableCustomImage name="marker-image-2" />
          </div>
          <Text
            name="p2"
            replacements={{
              'http://www.hosted-assembler-page.com': `${process.env.ASSEMBLER_URL}/${productName}/ford/en-us/root/${pageName}`
            }}
          />
          <PageCenteredContent>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', gap: 50 }}>
              <EditableCustomImage name="placeholder-image" />
              <EditableCustomImage name="placeholder-image-2" style={{ objectFit: 'contain' }} />
            </div>
          </PageCenteredContent>
          <Text name="ending" />
        </PageContent>
        <PageFooter>
          <EditableButton
            name="continue"
            onClick={() =>
              props.navigateForward!(
                `/embed-dynamic-pages/change-displayed-tile?pagePath=${pagePath}&pageName=${pageName}&productName=${productName}`
              )
            }
          />
        </PageFooter>
      </Page>
    </AEMPage>
  );
};

export default withAnimatedNavigation(MarkerComponent);
