import { AEMPage, EditableButton, EditableCustomImage } from '@fcamna/aem-library';

import withAnimatedNavigation, { AnimatedNavigationProps } from '../../../../utils/withAnimatedNavigation';
import { getAEMHost } from '../../config';
import { Page, PageCenteredContent, PageContent, PageFooter, PageHeader } from '../Page';
import { Text } from '../wrappedLibraryComponents/Text';

const AddHeaderFooter = (props: AnimatedNavigationProps) => {
  return (
    <AEMPage pagePath={`${process.env.AEM_CONTENT_BASE}/assembler-quickstart/add-header-footer`} aemHost={getAEMHost()}>
      <Page>
        <PageHeader>
          <Text name="header" />
        </PageHeader>
        <PageContent>
          <Text name="intro" />
          <Text name="p1" />
          <EditableCustomImage name="ford-credit-header" style={{ marginBottom: 20 }} />
          <EditableCustomImage name="ford-credit-footer" />
          <Text name="p1-2" />
          <EditableCustomImage name="ford-pro-header" style={{ marginBottom: 20 }} />
          <EditableCustomImage name="ford-pro-footer" />
          <Text name="p1-3" />
          <EditableCustomImage name="custom-header" style={{ marginBottom: 20 }} />
          <EditableCustomImage name="custom-footer" />
          <Text name="p1-4" />
          <Text name="p2" />
          <Text name="p3" />
          <PageCenteredContent>
            <Text name="p3-configs" />
            <EditableCustomImage name="header-footer-type" />
          </PageCenteredContent>
          <Text name="p3-cont" />
          <div style={{ display: 'flex', justifyContent: 'center', gap: 50, margin: '0 auto' }}>
            <EditableCustomImage name="step-1" />
            <EditableCustomImage name="step-2" />
            <EditableCustomImage name="step-3" />
          </div>
          <Text name="p5" />
          <EditableCustomImage name="check-box-image" style={{ margin: '0 auto' }} />
          <Text name="conclusion" />
        </PageContent>
        <PageFooter>
          <EditableButton
            name="cont"
            onClick={() => {
              props.navigateForward!(`/assembler-quickstart/routing`);
            }}
          />
        </PageFooter>
      </Page>
    </AEMPage>
  );
};

export default withAnimatedNavigation(AddHeaderFooter);
