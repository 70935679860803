import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { AEMPage, EditableButton } from '@fcamna/aem-library';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import withAnimatedNavigation, { AnimatedNavigationProps } from '../../../../utils/withAnimatedNavigation';
import { getAEMHost } from '../../config';
import { Page, PageContent, PageFooter, PageHeader } from '../Page';
import PageGenerator from '../PageGenerator';
import { Text } from '../wrappedLibraryComponents/Text';

const WarningText = styled(Text)`
  p {
    color: #d90606;
  }
`;

const CreateTilePage = (props: AnimatedNavigationProps) => {
  const isInEditor = useMemo(() => AuthoringUtils.isInEditor(), []);
  const { productName = 'quantum-logo' } = useLocation().state || {};
  const [pagePath, setPagePath] = useState<string>();
  const [pageName, setPageName] = useState<string>();

  return (
    <AEMPage pagePath={`${process.env.AEM_CONTENT_BASE}/embed-dynamic-pages/create-page-tile`} aemHost={getAEMHost()}>
      <Page>
        <PageHeader>
          <Text name="embedTileHeader-" />
        </PageHeader>
        <PageContent>
          <Text name="content" />
          <PageGenerator
            productName={productName}
            name="page-generator"
            sourcePage={process.env.SAMPLE_ASSEMBLER_MFE_PAGE}
            onCreated={(pagePath, pageName) => {
              setPagePath(pagePath || '');
              setPageName(pageName);
            }}
          />

          {(pageName || isInEditor) && (
            <>
              <Text
                name="aem-page-link"
                replacements={{
                  'http://page-link.com': `${process.env.AEM_AUTHOR_URL}/editor.html${pagePath}.html`
                }}
              />
              <WarningText name="warning" />
              <Text
                name="assembler-page"
                replacements={{
                  'http://www.new-assembler-page.com': `${process.env.ASSEMBLER_URL}/${productName}/ford/en-us/root/${pageName}`
                }}
              />
              <Text name="page-instructions" />
            </>
          )}
        </PageContent>
        <PageFooter>
          <EditableButton
            onClick={() =>
              props.navigateForward!(
                `/embed-dynamic-pages/marker-component?pagePath=${pagePath}&pageName=${pageName}&productName=${productName}`
              )
            }
            name="next"
          />
        </PageFooter>
      </Page>
    </AEMPage>
  );
};

export default withAnimatedNavigation(CreateTilePage);
