import React from 'react';
import ReactDOM from 'react-dom/client';

import Footer from './modules/Footer';
import Header from './modules/Header';
import Wizard from './modules/Wizard';

const div = document.getElementById('root');
const root = ReactDOM.createRoot(div as HTMLElement);
root.render(
  <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
    <Header />
    <Wizard />
    <Footer />
  </div>
);
