// eslint-disable-next-line
export function debounce(func: (...args: any[]) => void, timeout: number = 300) {
  let timer: NodeJS.Timeout;
  // eslint-disable-next-line
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, timeout);
  };
}

export function debounceV2(timeout: number = 300) {
  let timer: NodeJS.Timeout;
  // eslint-disable-next-line
  return (func: (...args: any) => void) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(), timeout);
  };
}

export const waitForAsync = (condition: () => Promise<boolean>, step: number = 100, timeout: number = 3000): Promise<boolean> => {
  let count = 0;
  return new Promise<boolean>((resolve) => {
    const handler = async () => {
      if (count >= timeout) {
        resolve(false);
      } else if (!(await condition())) {
        setTimeout(handler, step);
      } else {
        resolve(true);
      }
      count += step;
    };
    setTimeout(handler, step);
  });
};

export async function checkAEMPageExists(pagePath: string, isPublisher: boolean = true): Promise<boolean> {
  const aemServer = isPublisher ? process.env.AEM_PUBLISHER_URL : process.env.AEM_AUTHOR_URL;
  return fetch(`${aemServer}${pagePath}.model.json`, {
    headers: {
      Authorization: `Basic ${btoa(process.env.AEM_USER + ':' + process.env.AEM_PASSWORD)}`
    }
  })
    .then((res) => res.ok)
    .catch((error) => {
      console.error(error);
      return false;
    });
}

export async function checkAssemblerPageExists(productName: string, pageName: string) {
  return fetch(`https://wwwdev.creditbrandlb.ford.com/content/quantum-sandbox/${productName}/ford/en-us/root/${pageName}.model.json`).then(
    (res) => res.ok
  );
}

export async function checkTilePageExists(productName: string, pageName: string) {
  return fetch(`https://wwwdev.creditbrandlb.ford.com/content/quantum-sandbox/${productName}/ford/en-us/tiles/${pageName}.model.json`).then(
    (res) => res.ok
  );
}
