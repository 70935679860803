import { AEMPage, EditableButton, EditableCustomImage } from '@fcamna/aem-library';

import withAnimatedNavigation, { AnimatedNavigationProps } from '../../../../utils/withAnimatedNavigation';
import { getAEMHost } from '../../config';
import { Page, PageContent, PageFooter, PageHeader } from '../Page';
import { Text } from '../wrappedLibraryComponents/Text';

const AssemblerHosting = (props: AnimatedNavigationProps) => {
  return (
    <AEMPage pagePath={`${process.env.AEM_CONTENT_BASE}/host-assembler`} aemHost={getAEMHost()}>
      <Page>
        <PageHeader>
          <Text name="title" />
        </PageHeader>
        <PageContent>
          <Text name="prerequisite" />
          <EditableCustomImage name="product-folder-image" />
          <Text name="p2" />
          <Text name="step-2" />
          <div style={{ alignSelf: 'center' }}>
            <EditableCustomImage name="example-mapping-image" />
            <Text name="p3" replacements={{ 'http://quickstart.com': `/assembler-quickstart` }} />
          </div>
          <Text name="closing" />
        </PageContent>
        <PageFooter>
          <EditableButton className="continueBtn" onClick={() => props.navigateForward!('/')} />
        </PageFooter>
      </Page>
    </AEMPage>
  );
};

export default withAnimatedNavigation(AssemblerHosting);
