import { AEMPage, EditableButton, EditableRichTextEditor } from '@fcamna/aem-library';
import { useRef, useState } from 'react';
import styled from 'styled-components';

import { copyAEMPage, toAEMPagePath } from '../../../../utils/api';
import withAnimatedNavigation, { AnimatedNavigationProps } from '../../../../utils/withAnimatedNavigation';
import { getAEMHost } from '../../config';
import { EditableRegexInput } from '../EditableInput';

const AssemblerGuide = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px auto;
  align-items: center;
  max-width: 1440px;

  .productName {
    margin: 20px 0 100px;
    width: 500px;

    label {
      font-size: 18px;
    }
  }

  .subheader {
    width: 900px;
    margin-top: 30px;

    p {
      font-size: 18px;
    }
  }

  .continueBtn {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    button {
      width: 112px;
    }
  }
`;

function ProductName(props: AnimatedNavigationProps) {
  const [isDisabled, setIsDisabled] = useState(true);
  const productNameRef = useRef<HTMLInputElement | null>(null);
  return (
    <AssemblerGuide>
      <AEMPage pagePath={`${process.env.AEM_CONTENT_BASE}/new-page`} aemHost={getAEMHost()}>
        <div>
          <EditableRichTextEditor />
          <EditableRichTextEditor className="subheader" name="subheader" />
        </div>
        <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', justifyContent: 'center' }}>
          <EditableRegexInput
            name="productName"
            className="productName"
            regex={/^[a-zA-Z\-\s0-9]+$/}
            onValidChange={(v) => setIsDisabled(!v)}
            inputRef={productNameRef}
          />
        </div>
        <EditableButton
          className="continueBtn"
          isDisabled={isDisabled}
          onClick={() => {
            const productName = productNameRef.current?.value.replace(/\s/g, '-').toLowerCase() || '';
            setIsDisabled(true);
            copyAEMPage(`${process.env.AEM_REFERENCE_PAGE}${process.env.SAMPLE_ASSEMBLER_STATIC_PAGE}`, toAEMPagePath({ productName }), {
              remoteSPAUrl: process.env.ASSEMBLER_URL
            })
              .then((pagePath) => {
                props.navigateForward!('/new-page/create', {
                  pagePath,
                  productName
                });
              })
              .catch((e) => {
                console.log(e);
              })
              .finally(() => setIsDisabled(false));
          }}
        />
      </AEMPage>
    </AssemblerGuide>
  );
}

export default withAnimatedNavigation(ProductName);
