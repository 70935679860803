import { AEMPage, EditableButton, EditableCustomImage } from '@fcamna/aem-library';

import withAnimatedNavigation, { AnimatedNavigationProps } from '../../../../utils/withAnimatedNavigation';
import { getAEMHost } from '../../config';
import { Page, PageCenteredContent, PageFooter, PageHeader } from '../Page';
import { Text } from '../wrappedLibraryComponents/Text';

const Congratulations = (props: AnimatedNavigationProps) => {
  return (
    <AEMPage pagePath={`${process.env.AEM_CONTENT_BASE}/assembler-quickstart/congratulations`} aemHost={getAEMHost()}>
      <Page>
        <PageHeader>
          <Text name="intro" />
        </PageHeader>
        <PageCenteredContent>
          <div style={{ display: 'flex', gap: 30, justifyContent: 'center', marginTop: 15 }}>
            <EditableCustomImage />
            <EditableCustomImage />
            <EditableCustomImage />
          </div>
          <Text name="p1" />
        </PageCenteredContent>
        <PageFooter>
          <EditableButton
            name="back-to-home"
            onClick={() => {
              props.navigateForward!('/');
            }}
          />
        </PageFooter>
      </Page>
    </AEMPage>
  );
};

export default withAnimatedNavigation(Congratulations);
