import './Footer.scss';

import { Mode, ThemeName, ThemeProvider } from '@fcamna/shared-component-foundations';
const Footer = () => {
  return (
    <ThemeProvider themeName={ThemeName.FORD_CREDIT} mode={Mode.DARK_MODE}>
      <div className="Footer">
        <div className="Footer__text-container">
          <span>Powered by</span>
          <span>
            <b>Quantum</b>
          </span>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Footer;
