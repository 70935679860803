import { Icon, IconButton } from '@fcamna/react-library';
import { Mode, ThemeName, ThemeProvider } from '@fcamna/shared-component-foundations';
import { useState } from 'react';
import styled from 'styled-components';

const StyledCode = styled.div`
  width: 85%;
  margin: auto;
  background-color: #00095b;
  color: white;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  cursor: text;
  position: relative;
  font-size: 16px;
  white-space: nowrap;

  .code-block {
    padding: 25px 0 25px 35px;
  }

  .command-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .copy-button-container {
    border: none;
    border-radius: 10px;
    align-self: flex-start;
    margin: 3px;
  }
`;

const Code = (props: { commands: string[]; maxWidth?: number | string; hideCopyButton?: boolean; className?: string }) => {
  const [icon, setIcon] = useState(<Icon name="copy" color="white" />);

  return (
    <StyledCode className={props.className} style={{ maxWidth: props.maxWidth }}>
      <div className="code-block" style={{ overflow: 'auto', flexGrow: 1 }}>
        <div className="command-container">
          {props.commands.map((command, index) => {
            return <code key={`command-${index}`}>{command}</code>;
          })}
        </div>
      </div>
      <ThemeProvider themeName={ThemeName.FORD_PRO} mode={Mode.DARK_MODE}>
        {!props.hideCopyButton && (
          <div className="copy-button-container">
            <IconButton
              size="medium"
              variant="tertiary"
              icon={icon}
              onClick={() => {
                navigator.clipboard.writeText(props.commands.join('\n'));
                setIcon(<Icon name="checkmark" size="xl" color="#00e83e" />);
              }}
            />
          </div>
        )}
      </ThemeProvider>
    </StyledCode>
  );
};

export default Code;
