import styled from 'styled-components';

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  align-self: center;
  max-width: 1440px;
  width: 100%;
`;

export const PageHeader = styled.div`
  margin-top: 50px;
  margin-bottom: 30px;
  width: 100%;
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  flex-grow: 1;
  width: 100%;

  .text-content {
    h2 {
      margin-top: 50px;
      align-self: center;
      margin-bottom: 30px;
    }

    p {
      font-size: 18px;
    }
  }
`;

export const PageFooter = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
  width: 100%;
  gap: 200px;
  align-items: center;
`;

export const PageCenteredContent = styled(PageContent)`
  align-items: center;
`;
