import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { AEMPage, EditableButton } from '@fcamna/aem-library';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import withAnimatedNavigation, { AnimatedNavigationProps } from '../../../../utils/withAnimatedNavigation';
import { getAEMHost } from '../../config';
import { Page, PageContent, PageFooter, PageHeader } from '../Page';
import PageGenerator from '../PageGenerator';
import { Text } from '../wrappedLibraryComponents/Text';

function AppName(props: AnimatedNavigationProps) {
  const isInEditor = useMemo(() => AuthoringUtils.isInEditor(), []);
  const { productName = 'quantum-logo' } = useLocation().state || {};
  const [pagePath, setPagePath] = useState<string>();
  const [appName, setAppName] = useState<string>();
  return (
    <AEMPage pagePath={`${process.env.AEM_CONTENT_BASE}/dynamic-page/app-name`} aemHost={getAEMHost()}>
      <Page>
        <PageHeader>
          <Text name="title" />
        </PageHeader>
        <PageContent>
          <Text name="instructions" />
          <PageGenerator
            productName={productName}
            spaURL={process.env.TILE_SPA_URL}
            sourcePage={process.env.SAMPLE_TILE_PAGE}
            rootDirectory="tiles"
            name="page-generator"
            onCreated={(pagePath, pageName) => {
              setPagePath(pagePath);
              setAppName(pageName);
            }}
          />
          {(!!appName || isInEditor) && <Text name="next-steps" />}
        </PageContent>
        <PageFooter>
          <EditableButton
            isDisabled={!appName}
            className="continueBtn"
            onClick={() => {
              props.navigateForward!('/dynamic-page/instructions', {
                productName,
                appName,
                pagePath
              });
            }}
          />
        </PageFooter>
      </Page>
    </AEMPage>
  );
}

export default withAnimatedNavigation(AppName);
