import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export interface AnimatedNavigationProps extends JSX.IntrinsicAttributes {
  navigateForward?: (to: string, state?: object) => void;
  navigateBackward?: (to: string, state?: object) => void;
}

const NavContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  > *:first-child {
    flex-grow: 1;
  }
`;

export default function withAnimatedNavigation<P extends AnimatedNavigationProps>(Component: React.FC<P>, props?: P) {
  function AnimatedNavigationWrappedComponent() {
    const { type } = useLocation().state || '';
    const navigate = useNavigate();
    const navContainerRef = useRef<HTMLDivElement>(null);

    const foundProps = props ? props : ({} as P);

    useEffect(() => {
      setTimeout(() => navContainerRef.current?.classList.remove(type), 20);
    }, [navContainerRef, type]);

    const navigateForward = (to: string, state?: object) => {
      navContainerRef.current?.classList.add('previous');
      setTimeout(() => navigate(to, { state: { type: 'next', ...state } }), 250);
    };

    const navigateBackward = (to: string, state?: object) => {
      navContainerRef.current?.classList.add('next');
      setTimeout(() => navigate(to, { state: { type: 'previous', ...state } }), 250);
    };

    return (
      <NavContainer className={`nav-container ${type}`} ref={navContainerRef}>
        <Component {...foundProps} navigateBackward={navigateBackward} navigateForward={navigateForward} />
      </NavContainer>
    );
  }

  return AnimatedNavigationWrappedComponent;
}
