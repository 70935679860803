import { EditableButton } from '@fcamna/aem-library';
import styled from 'styled-components';

import { Text } from './wrappedLibraryComponents/Text';

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  padding: 32px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 8px;
  border: 1px solid #d1d5d9;
  height: 230px;
  width: 100%;
  min-width: 555px;

  h1 {
    color: #00142e;
    margin-bottom: 12px;
    line-height: 40px;
    font-weight: 600;
    font-size: 24px;
    font-family: 'Ford-F1', sans-serif;
  }

  p {
    font-weight: unset;
    color: #38485c;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }
`;

const CardFooter = styled.div`
  justify-self: flex-end;
  margin-top: 24px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;

  p {
    margin: 0;
    color: #38485c;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    font-style: italic;
  }
`;

const Card = (props: { name: string; onClick: () => void; disabled?: boolean; className?: string }) => {
  return (
    <StyledCard className={props.className}>
      <Text name={`${props.name}-header-text`} />
      <div style={{ flexGrow: 1 }}>
        <Text name={`${props.name}-subheader-text`} />
      </div>
      <CardFooter>
        <EditableButton isDisabled={props.disabled} name={`${props.name}-button`} onClick={props.onClick} />
        <div style={{ display: 'flex' }}>
          <Text name="role-prefix" />
          &nbsp;
          <Text name={`${props.name}-role`} />
        </div>
      </CardFooter>
    </StyledCard>
  );
};

export default Card;
