import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { AEMPage, EditableCustomImage, EditableRichTextEditor } from '@fcamna/aem-library';
import React, { CSSProperties, useState } from 'react';
import styled from 'styled-components';

import { getAEMHost } from '../config';

const PublishInstructionsMessage = styled(EditableRichTextEditor)`
  h3 {
    font-weight: 100;
  }
`;

const StyledPublishInstructions = styled.div`
  width: 80%;
  border-radius: 12px;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
  height: fit-content;
  padding: 25px;
  margin: 30px auto 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  gap: 25px;

  .publishImage {
    display: flex;
    justify-content: center;
  }
`;

const TextButton = styled.button`
  color: #066fef;
  border: none;
  background: none;
  font-family: FordMotion, sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: bolder;
  font-style: italic;
  cursor: pointer;
  padding: 0 0 0 5px;
`;

const AnimatedArrow = styled.div`
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  width: 4px;
  height: 4px;
  margin: 3px;
  transform: rotate(-45deg);
  transition: 0.3s;
  position: relative;
  top: 3px;
  left: 10px;

  &.down {
    top: -1px;
    transform: rotate(45deg);
  }
`;

export function PublishInstructions(props: { styles?: CSSProperties }) {
  const [showPublishInstructions, setShowPublishInstruction] = useState(AuthoringUtils.isInEditor());

  return (
    <AEMPage pagePath={`${process.env.AEM_CONTENT_BASE}/components/publish-instructions`} aemHost={getAEMHost()}>
      <span style={{ display: 'flex', alignItems: 'center', ...props.styles }}>
        <PublishInstructionsMessage name="showMessage" />
        <TextButton onClick={() => setShowPublishInstruction(!showPublishInstructions)}>here</TextButton>
        <AnimatedArrow className={`${showPublishInstructions ? 'down' : ''}`} />
      </span>

      {showPublishInstructions && (
        <StyledPublishInstructions>
          <EditableRichTextEditor name="publishHeader" />
          <EditableRichTextEditor removeDefaultStyles={true} name="publishInstructions1" className="text" />
          <EditableCustomImage className="publishImage"></EditableCustomImage>
          <EditableRichTextEditor removeDefaultStyles={true} name="publishInstructions2" className="text" />
        </StyledPublishInstructions>
      )}
    </AEMPage>
  );
}
