import { AEMPage, EditableButton, EditableCustomImage } from '@fcamna/aem-library';

import withAnimatedNavigation, { AnimatedNavigationProps } from '../../../../utils/withAnimatedNavigation';
import { getAEMHost } from '../../config';
import { Page, PageCenteredContent, PageContent, PageFooter, PageHeader } from '../Page';
import { Text } from '../wrappedLibraryComponents/Text';

const Routing = (props: AnimatedNavigationProps) => {
  return (
    <AEMPage pagePath={`${process.env.AEM_CONTENT_BASE}/assembler-quickstart/routing`} aemHost={getAEMHost()}>
      <Page>
        <PageHeader>
          <Text name="header" />
        </PageHeader>
        <PageContent>
          <Text name="intro" />
          <Text name="p1" />
          <PageCenteredContent style={{ flexDirection: 'column', alignItems: 'center', gap: 50 }}>
            <EditableCustomImage name="routing-config-image" />
            <EditableCustomImage name="routing-config-map" />
          </PageCenteredContent>
          <Text name="routing-config-text" />
          <Text name="p2" />
          <PageCenteredContent style={{ flexDirection: 'column', alignItems: 'center', gap: 30 }}>
            {/*TODO: Rename the images below and re-author*/}
            <EditableCustomImage name="home-page-image" />
            <EditableCustomImage name="author-page-image" />
          </PageCenteredContent>
          <Text name="p3" />
          <Text name="two-image-text" />
          <Text name="p4" />
          <EditableCustomImage name="disabled-routing-image" style={{ margin: '0 auto' }} />
          <Text name="mfe-text" />
          <Text name="p5" />
          <EditableCustomImage name="direct-page-path-image" style={{ margin: '0 auto' }} />
          <Text name="conclusion" />
        </PageContent>
        <PageFooter>
          <EditableButton
            name="cont"
            onClick={() => {
              props.navigateForward!(`/assembler-quickstart/maintenance-and-error-page`);
            }}
          />
        </PageFooter>
      </Page>
    </AEMPage>
  );
};

export default withAnimatedNavigation(Routing);
