import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { AEMPage, EditableRichTextEditor } from '@fcamna/aem-library';
import { TextArea } from '@fcamna/react-library';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { getAEMHost } from '../config';
import Code from './Code';

const StyledJFrogInstructions = styled.div`
  width: 80%;
  border-radius: 12px;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
  height: fit-content;
  padding: 25px;
  margin: auto;
  display: flex;
  flex-direction: column;
  background-color: #30d55947;
  gap: 25px;
`;

const StyledTextArea = styled(TextArea)`
  width: 80%;
  margin: auto;

  textarea {
    resize: none;
  }
`;

const InstructionTextEditor = styled(EditableRichTextEditor)`
  width: 80%;
  margin: auto;

  ol {
    padding-left: 24px;
    li {
      line-height: 30px;
    }
  }
`;

const JfrogMessage = styled(EditableRichTextEditor)`
  h3 {
    font-weight: 100;
  }
`;

const TextButton = styled.button`
  color: #066fef;
  border: none;
  background: none;
  font-family: FordMotion, sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: bolder;
  font-style: italic;
  cursor: pointer;
  padding: 0 0 0 5px;
`;

const AnimatedArrow = styled.div`
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  width: 4px;
  height: 4px;
  margin: 3px;
  transform: rotate(-45deg);
  transition: 0.3s;
  position: relative;
  top: 3px;
  left: 10px;

  &.down {
    top: -1px;
    transform: rotate(45deg);
  }
`;

const JFrogInstructions = () => {
  const [token, setToken] = useState<string>('');
  const [showJFrogInstructions, setShowJfFrogInstructions] = useState(AuthoringUtils.isInEditor());

  const jfrogAuthToken = `export JFROG_AUTH_TOKEN=${token}`;
  const jfrogQuantumConfig = `npm config set @quantum:registry=https://jfrog.ford.com/artifactory/api/npm/digitalplatform-npm`;
  const jfrogQuantumAuth = `npm config set //jfrog.ford.com/artifactory/api/npm/digitalplatform-npm/:_authToken=$JFROG_AUTH_TOKEN`;
  const jfrogUclAuth = `npm config set //jfrog.ford.com/artifactory/api/npm/ucl-fp-fc-library/:_authToken=$JFROG_AUTH_TOKEN`;

  const onTokenChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => setToken(e.target.value), []);

  return (
    <AEMPage pagePath={`${process.env.AEM_CONTENT_BASE}/components/jfrog-instructions`} aemHost={getAEMHost()}>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <JfrogMessage name="showJfrogMessage" />
        <TextButton onClick={() => setShowJfFrogInstructions(!showJFrogInstructions)}>click here</TextButton>
        <AnimatedArrow className={`${showJFrogInstructions ? 'down' : ''}`} />
      </span>
      {showJFrogInstructions && (
        <StyledJFrogInstructions>
          <EditableRichTextEditor name="jFrogHeader" />
          <InstructionTextEditor removeDefaultStyles={true} name="jFrogInstructions" />
          <StyledTextArea placeholder="token" onChange={onTokenChange} />
          {(!!token || AuthoringUtils.isInEditor()) && (
            <>
              <Code commands={[jfrogAuthToken, jfrogQuantumConfig, jfrogQuantumAuth, jfrogUclAuth]} maxWidth={'80%'} />
              <EditableRichTextEditor name="jFrogRunText" />
            </>
          )}
        </StyledJFrogInstructions>
      )}
    </AEMPage>
  );
};

export default JFrogInstructions;
